import { StaticImage } from "gatsby-plugin-image";
import CareerDetail from "../../features/career_detail";

export default (props: Object) => {
  return (
    <CareerDetail
      {...props}
      locales={{
        it: {
          title: "Digital product designer",
          seoDescription:
            "Essere lofter significa curiosità, ambizione e passione. Lavoriamo per portare innovazione nel mondo del software e dei prodotti digitali. Ti va di unirti a noi?",
          roles: [
            "Progetterai interfacce di app e piattaforme web, avendo come primo obiettivo la migliore user experience per gli utenti",
            "Organizzerai workshop con gli stakeholder dei progetti, aiutandoli a schiarire le idee sulle direzioni da prendere",
            "Collaborerai con il team di sviluppo, in modo da rendere fluido il passaggio dalla progettazione alla realizzazione",
            "Creerai prototipi interattivi delle tue interfacce, testandoli con utenti e clienti e confrontando i dati con i risultati definiti per il progetto",
            "Scriverai documentazione di progetto, per incidere sulla pietra le informazioni più importanti",
            "Pianificherai il tuo lavoro insieme al resto del team di design",
            "Farai tanta ricerca!",
          ],
          prerequisites: [
            "Passione per prodotti digitali",
            "Esperienza precedente in aziende di consulenza o prodotto in ambito UX/UI o esperienza freelance",
            "Utilizzo consolidato di Figma",
            "Conoscenza dei processi di design di prodotti digitali",
            "Buona conoscenza dell’inglese",
            "Apprezzata laurea o diploma equivalente in ambito design",
          ],
          yearsOfExperience: 2,
          requiredTools: [
            {
              name: "Figma",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/uxDesignerLogo2.png"
                  }
                />
              ),
            },
            {
              name: "Maze",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/uxDesignerLogo3.png"
                  }
                />
              ),
            },
            {
              name: "Slack",
              image: (
                <StaticImage
                  alt=""
                  src={
                    "../../features/career_detail/images/uxDesignerLogo1.png"
                  }
                />
              ),
            },
          ],
        },
      }}
    />
  );
};
